import { useState, createContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signOut, signInWithEmailAndPassword } from '../Utils/firebaseConfig'
import { toast } from '@mobiscroll/react';

import { functions, httpsCallable, database, doc, getDoc, setDoc, onSnapshot } from "../Utils/firebaseConfig";

import { updateUserSetting } from '../Utils/firebaseCRUD';
import { consoleSandbox } from '@sentry/utils';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [runOnce, setRunOnce] = useState(0);
  const [userSettings, setUserSettings] = useState([])
  const navigate = useNavigate();





  useEffect(() => {

    const fetchUserSettings = async (email) => {
      const docRef = doc(database, "user-settings", email);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserSettings(docSnap.data());
        window.localStorage.setItem("MotionAirSettings", JSON.stringify(docSnap.data()));
        // Throws an error.
        console.log('Settings fetched...');
        if (docSnap.data().startPage === 10) {
          navigate("/calendar")
          console.log(10)
        } else if (docSnap.data().startPage === 20) {
          navigate("/agenda")
          console.log(20)
  
        } else if (docSnap.data().startPage === 30) {
          navigate("/todo")
          console.log(30)
  
        } else if (docSnap.data().startPage === 40) {
          navigate("/Settings")
          console.log(40)
        } else {
          navigate("/agenda")
        }

      } else {
        await setDoc(docRef, {
          userEmail: email
        });
        console.log('Created settings file...');
      }
    }

    //   if (localStorage.getItem('MotionAirSettings') !== null) {
    //     let localUserSettings = window.localStorage.getItem("MotionAirSettings");
    //     console.log(JSON.parse(localUserSettings));
    //     setUserSettings(JSON.parse(localUserSettings))
    // } else {
    //     console.log(`No local storage found`);
    // }
    console.log(userSettings)
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      fetchUserSettings(currentUser.email)
      const docRef = doc(database, "user-settings", currentUser.email);
      setUser(currentUser);
      // navigate(userSettings.startPage);
      // console.log(userSettings.startPage)


      // -------------------------------------            LISTENING FOR SETTINGS CHANGES!!!
      onSnapshot(docRef, snapshot => {
        setUserSettings(snapshot.data())
      })
    });


    return () => {
      unsubscribe()
      console.log('unsubscribed!')
    }
  }, []);




  const fetchUserSettings = async (email) => {
    const docRef = doc(database, "user-settings", email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUserSettings(docSnap.data());
      window.localStorage.setItem("MotionAirSettings", JSON.stringify(docSnap.data()));
      // Throws an error.
      console.log('Settings fetched...');

    } else {
      await setDoc(docRef, {
        userEmail: email
      });
      console.log('Created settings file...');
    }




    // const usersRef = database.collection('user-settings').doc(user.email)

    // usersRef.get().then((docSnapshot) => {
    //   if (docSnapshot.exists) {
    //     usersRef.onSnapshot((doc) => {
    //       console.log('document extist!')
    //     });
    //   } else {
    //     usersRef.set({ userEmail: user.email }) // create the document
    //   }
    // });

    // const saveEdit = (event, title, description) => {
    //   updateUserSetting(userSettingsDatabase, event.id, title, description, event.checked, event.order)
    // }

  }


  const handleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then((result) => {

      //CALL CLOUD FUNCTION TO CHECK IF USER IS ALLOWED IN
      const checkUserIsEmployee = httpsCallable(functions, 'checkUserIsEmployee')
      checkUserIsEmployee({ currentUser: result.user.email }).then(res => {
        if (res.data.isRegistered === false) {
          signOut(auth)
            .then(() => {
              console.log("warning", `You are logged out!`);
              console.log("Contact site administrator to gain access.");
              setUser(null);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {

          console.log('Welcome home', result.user.displayName)
          // fetchUserSettings(result.user.email) //userSettingsDatabase
        }
      })

      setUser(result.user);
    });
  };


  const handleLoginWithEmail = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setUser(userCredential.user);
        navigate(userSettings.startPage);
        if (userSettings.startPage === 10) {
          navigate("/calendar")
          console.log(10)
        } else if (userSettings.startPage === 20) {
          navigate("/agenda")
          console.log(20)
  
        } else if (userSettings.startPage === 30) {
          navigate("/todo")
          console.log(30)
  
        } else if (userSettings.startPage === 40) {
          navigate("/Settings")
          console.log(40)
        } else {
          navigate("/agenda")
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        let message = error.message.replace('Firebase:', '');

        toast({
          message: `${message}`,
          color: 'danger'
        });
        console.log(errorMessage, errorCode)
      });
  };


  const handleLogout = () => {
    console.log("LOGOUT");
    signOut(auth)
      .then(() => {
        console.log("warning", `You are logged out!`);
        setUser(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // console.log('test')

  const value = {
    user,
    userSettings,
    onLogin: handleLogin,
    onLoginWithEmail: handleLoginWithEmail,
    onLogout: handleLogout,
  };

  return <AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>;
};
