import Agenda from "../Components/Agenda";
import "./LogIn.css";
import Loading from "../Components/Loading"

import { useAuth } from "../Hooks/useAuth";

const AgendaView = () => {

  const { userSettings } = useAuth()

  return (
    <div>
      {(userSettings !== []) ?
      <Agenda />
      :
      <Loading />
    }
      </div>
  );
};

export default AgendaView;
