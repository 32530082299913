import Settings from "../Components/Settings/Settings";
// import "./Settings.css";

const SettingsView = () => {
  return (
    <div>
      <Settings />
    </div>
  );
};

export default SettingsView;
