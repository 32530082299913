import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import { UserContextProvider } from "./Utils/UserContextProvider";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <UserContextProvider>
      <App />
    </UserContextProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// {
//   onUpdate: (registration) => {
//     confirm({
//       title: "New version available",
//       message: "Press Reload to get latest changes.",
//       okText: "Reload",
//       cancelText: "Cancel",
//       display: "bottom",
//       callback: function (result) {
//         if (result === true) {
//           if (registration && registration.waiting) {
//             registration.waiting.postMessage({ type: "SKIP_WAITING" });
//           }
//           window.location.reload();
//         } else return;
//       },
//     });
//     // console.log('NEW VERSION OF SERVICE WORKER')
//   },
// }
