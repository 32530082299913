import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Layout from "./Pages/Layout";
import Calendar from "./Pages/Calendar";
import LogIn from "./Pages/LogIn";
import NoPage from "./Pages/NoPage";
import Agenda from "./Pages/Agenda";
import Todo from "./Pages/Todo";
import Settings from "./Pages/Settings";
import "./index.css";

import "./Pages//Layout.css";

import { ProtectedRoute } from "./Components/ProtectedRoutes";
import { AuthProvider } from "./Hooks/AuthProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useServiceWorker } from "./Hooks/useServiceWorker";
import { confirm } from "@mobiscroll/react";

// PROTECTS ROUTES IF USER IS NOT LOGGED IN
const App = () => {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3498B7",
      },
      secondary: {
        main: "##2E2E2E",
      },
    },
    typography: {
      fontFamily: [
        "Montserrat",
        "sans-serif",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  useEffect(() => {
    if (showReload && waitingWorker) {
      confirm({
        title: "New version available",
        message: "Press Reload to get latest changes.",
        okText: "Reload",
        cancelText: "Cancel",
        display: "bottom",
        callback: function (result) {
          if (result === true) {
            reloadPage();
          } else return;
        },
      });
    } else {
    }
  }, [waitingWorker, showReload, reloadPage]);

  return (
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<Layout hideHeaderPaths={["/"]} />}>
              <Route index element={<LogIn />} />
              <Route
                path="Calendar"
                element={
                  <ProtectedRoute>
                    <Calendar />
                  </ProtectedRoute>
                }
              />
              <Route
                path="Agenda"
                element={
                  <ProtectedRoute>
                    <Agenda />
                  </ProtectedRoute>
                }
              />
              <Route
                path="Todo"
                element={
                  <ProtectedRoute>
                    <Todo />
                  </ProtectedRoute>
                }
              />
              <Route
                path="Settings"
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              {/* <Route path="*" element={<NoPage />} /> */}
            </Route>
          </Routes>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
