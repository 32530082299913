import CalendarComponent from '../Components/CalendarComponent';
import { ClientSideSuspense } from "@liveblocks/react";
import { RoomProvider } from "../Utils/liveblocksConfig";

import './Calendar.css';

function App() {
  
  return (
    <div>
        <RoomProvider id="motion-air-calendar" initialPresence={{ cursor: null }}>
          <ClientSideSuspense fallback={<div>Loading...</div>}>
            {() => <CalendarComponent />}
          </ClientSideSuspense>
        </RoomProvider>
    </div>
  );
}

export default App;
