import React, { useContext, useEffect } from 'react';

import { Eventcalendar, toast, setOptions, Popup, Button, Input, Textarea, Datepicker, localeNo, snackbar, Checkbox, Switch, CalendarNav, CalendarPrev, CalendarNext, CalendarToday, formatDate } from '@mobiscroll/react';

import { onAuthStateChanged, auth, collection, database } from '../Utils/firebaseConfig'
import { v4 as uuidv4 } from 'uuid';

import { fetchEvents, addEvents, updateEvent, deleteEvent, restoreEvent, fetcSettings } from '../Utils/firebaseCRUD';
import { resources } from "../Utils/resources"

import '../styles.scss'
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import './CalendarComponent.css'

import { onSnapshot } from 'firebase/firestore';

import Cursor from "./Cursor"
import { Avatar } from './Avatar';
import { useOthers, useUpdateMyPresence, useSelf } from "../Utils/liveblocksConfig";
import CursorIndicator from './CursorIndicator';

import holidays from 'holidays-norway';


// HOOKS
import { useAuth } from "../Hooks/useAuth";
import { Height, Settings, Today } from '@mui/icons-material';
import { consoleSandbox } from '@sentry/utils';
import { useCallback } from 'react';
import { useMatch } from 'react-router-dom';

//MOTIONAIR

setOptions({
    locale: localeNo,
    theme: 'ios',
    themeVariant: 'light'
});

const responsivePopup = {
    medium: {
        display: 'anchored',
        width: 400,
        fullScreen: false,
        touchUi: false
    },
    large: {
        display: 'center',
        maxHeight: '80vh',
        maxWidth: 800,
        fullScreen: true,
        touchUi: true
    }
};
const colorPopup = {
    medium: {
        display: 'anchored',
        touchUi: false,
        buttons: []
    }
}

const pastel = () => {
    var hue = Math.floor(Math.random() * 360);
    var pastel = 'hsl(' + hue + ', 100%, 80%)';
    return pastel
}
//EN MULIGHET FOR Å FÅ FLERE FARGER::
// const colors = [pastel(), pastel(), pastel(), pastel(), pastel(), pastel(), pastel(), pastel(), pastel(), pastel()];

const colors = ['#ffeb3c', '#ff9900', '#ff99ff', '#66ffcc', '#9c26b0', '#3f51b5', '#5ac8fa', '#009788', '#4baf4f', '#7e5d4e'];

const Calendar = () => {
    // CUSTOM CODE:
    // DATABASE CONSTANT, CHANGE FOR TESTING PURPOSES:
    const eventDatabase = process.env.REACT_APP_SERVER_LOCATION

    const { user, userSettings } = useAuth();
    const userColor = userSettings["cursor-color"]
    // console.log(userSettings)

    const [isChecked, setIsChecked] = React.useState([]);
    const [isEditable, setIsEditable] = React.useState(false);
    const [isBusy, setIsBusy] = React.useState(false);
    const busyColor = '#ff0000';
    const [isX, setIsX] = React.useState(false);
    const [mobileShow, setMobileShow] = React.useState(true);
    const [zoomLevel, setZoomLevel] = React.useState(1440);
    const [isHoverOpen, setIsHoverOpen] = React.useState(false);
    const timerRef = React.useRef(null);
    const [eventContent, setEventContent] = React.useState([]);
    const [calendarColors, setCalendarColors] = React.useState();
    const timelineRef = React.useRef(null);
    // const [isLoggingIn, setIsLoggingIn] = React.useState(true);
    const [norwegianHolidays, setNorwegianHolidays] = React.useState([]);
    const [isHoverDayOpen, setIsHoverDayOpen] = React.useState(false);
    const [holidayName, setHolidayName] = React.useState(null);
    const [holidayAnchor, setHolidayAnchor] = React.useState(null);





    // LiveBlocks
    const others = useOthers();
    const currentUser = useSelf();
    const updateMyPresence = useUpdateMyPresence();
    const [scrollPos, setScrollPos] = React.useState({ x: 0, y: 0, width: 0 })



    // STANDARD CODE:
    const [myEvents, setMyEvents] = React.useState();
    const [tempEvent, setTempEvent] = React.useState(null);
    const [isOpen, setOpen] = React.useState(false);
    const [isEdit, setEdit] = React.useState(false);
    const [anchor, setAnchor] = React.useState(null);
    const [start, startRef] = React.useState(null);
    const [end, endRef] = React.useState(null);
    const [popupEventTitle, setTitle] = React.useState('');
    const [popupEventDescription, setDescription] = React.useState('');
    const [popupEventAllDay, setAllDay] = React.useState(true);
    const [popupEventDate, setDate] = React.useState([]);
    const [popupEventTime, setTime] = React.useState([]);
    const [popupEventStatus, setStatus] = React.useState('busy');
    const [mySelectedDate, setSelectedDate] = React.useState(new Date());
    const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
    const [colorAnchor, setColorAnchor] = React.useState(null);
    const [selectedColor, setSelectedColor] = React.useState('');
    const [tempColor, setTempColor] = React.useState('');
    const colorPicker = React.useRef();
    const colorButtons = React.useMemo(() => [
        'cancel',
        {
            text: 'Set',
            keyCode: 'enter',
            handler: () => {
                setSelectedColor(tempColor);
                setColorPickerOpen(false);
            },
            cssClass: 'mbsc-popup-button-primary'
        }
    ], [tempColor]);

    let viewSettings = {
        timeline: {
            type: 'year',
            weekNumbers: true,
            rowHeight: 'variable',
            timeCellStep: zoomLevel
        }
    };

    function findNestedObj(entireObj, keyToFind, valToFind) {
        let foundObj;
        JSON.stringify(entireObj, (_, nestedValue) => {
            if (nestedValue && nestedValue[keyToFind] === valToFind) {
                foundObj = nestedValue;
            }
            return nestedValue;
        });
        return foundObj;
    };


    //---------------------------- OBS PÅ QUERYSELECTOR HER. UPDATES KAN ENDRE DEN ---------------------//

    // LIVEBLOCKS MULTI CURSOR
    useEffect(() => {
        const container = document.querySelector('.mbsc-timeline-grid-scroll')
        const containerWidth = document.querySelector('.mbsc-timeline-header-bg.mbsc-flex-1-0.mbsc-flex.mbsc-ios')
        if (containerWidth !== null) {
            const containerWidthBox = containerWidth.getBoundingClientRect();
            container.addEventListener('scroll', () => {
                setScrollPos({ x: container.scrollLeft, y: container.scrollTop, width: containerWidthBox.width })
            })
        }
    }, [scrollPos])

    // console.log('userColor: ',userColor)



    const mouseMove = (e) => {
        // const rect2 = document.querySelector('.mbsc-timeline-header-bg.mbsc-flex-1-0.mbsc-flex.mbsc-ios');
        // console.log(rect2)
        const rect = document.querySelector('.mbsc-timeline-header-bg.mbsc-flex-1-0.mbsc-flex.mbsc-ios').getBoundingClientRect();
        let x = 0; // x position within the scrollable div
        let y = 0;  // y position within the scrollable div

        x = e.clientX - rect.left + 194; // x position within the scrollable div
        y = e.clientY - rect.top + 98;  // y position within the scrollable div
        // console.log('test: ', currentUser.connectionId)
        updateMyPresence({
            name: user.email,
            displayName: user.displayName,
            cursor: { x, y },
            scrollPosition: scrollPos,
            iconImage: userSettings.showProfilePictureInAvatar ? userSettings.userImage : "",
            cursorImage: userSettings.showProfilePictureInCursor ? userSettings.showProfilePictureInCursor : "",
            color: userColor ? userColor : { color: "#808080", dim: "#414141" },
            lastActive: new Date()
        });
    }





    const getArr = React.useCallback((arr, flat = []) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].children && arr[i].children.length) {
                flat.push(arr[i]);
                console.log(arr[i])
                getArr(arr[i].children, flat);
            }
        }
        return flat;
    })


    useEffect(() => {
        //THIS INITIATES REALTIME LISTENERS
        const userRef = collection(database, eventDatabase);
        const unsubscribe = onSnapshot(userRef, snapshot => {
            setMyEvents(snapshot.docs.map(doc => ({
                id: doc.data().id,
                title: doc.data().title,
                description: doc.data().description,
                start: doc.data().start.toDate(),
                end: doc.data().end.toDate(),
                allDay: doc.data().allDay,
                status: doc.data().status,
                color: doc.data().color,
                resource: doc.data().resource,
                editable: doc.data().editable,
                busy: doc.data().busy
            })))
        })

        return () => {
            unsubscribe()
            console.log('unsubscribed!')
        }
    }, [eventDatabase])


    //USED FOT ALTERNATIVE BACKGROUND HEX RENDERING
    function LightenDarkenColor(col, amt) {
        col = parseInt(col, 16);
        let color = (((col & 0x0000FF) + amt) | ((((col >> 8) & 0x00FF) + amt) << 8) | (((col >> 16) + amt) << 16)).toString(16);
        return "#" + color;
    }


    //USE EFFECT FOR RESOURCE COLOR UPDATES
    useEffect(() => {
        // console.log(new Date())
        const freelanceResourceAvailable = () => {
            let freelanceResources = [5, 6, 7, 8, 9]
            let equipmentResources = [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]
            let allResources = [100, 1001, 101, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]
            let colors = [
                {
                    date: new Date(),
                    background: 'rgb(227, 255, 227)',
                    resource: allResources,
                }, {
                    background: '#FFF3F4',
                    resource: Array.from(Array(101).keys()),
                    recurring: {
                        repeat: 'weekly',
                        weekDays: 'SU'
                    }
                }, {
                    background: '#f7f2f2',
                    resource: Array.from(Array(101).keys()),
                    recurring: {
                        repeat: 'weekly',
                        weekDays: 'SA'
                    }
                }
            ]
            if (myEvents !== undefined) {
                myEvents.map((event) => {
                    if (event.resource.some(r => freelanceResources.indexOf(r) >= 0)) {  // IF EVENT IN FREELANCE GROUP
                        //USED FOT ALTERNATIVE BACKGROUND HEX RENDERING
                        // let eventColor = event.color.substring(1)
                        let startDate
                        let endDate
                        if (event.allDay === true) {
                            let startTimestamp = Date.parse(event.start)
                            let endTimestamp = Date.parse(event.end)
                            let startdate = new Date(startTimestamp)
                            let enddate = new Date(endTimestamp)
                            startDate = startdate
                            endDate = enddate
                            startDate.setHours(0, 0, 1)
                            endDate.setHours(24, 0, 0)
                        }
                        colors.push({
                            start: event.allDay ? startDate : event.start,
                            end: event.allDay ? endDate : event.end,
                            resource: [1001],
                            // background: LightenDarkenColor(eventColor,50),
                            background: `${event.color}${Math.floor(0.3 * 255).toString(16).padStart(2, 0)}` // 0.3 opacity and event.color HEX
                        })
                        if (!event.editable) {  // IF EVENT IN FREELANCE GROUP
                            //USED FOT ALTERNATIVE BACKGROUND HEX RENDERING
                            // let eventColor = event.color.substring(1)
                            let startDate
                            let endDate
                            if (event.allDay === true) {
                                let startTimestamp = Date.parse(event.start)
                                let endTimestamp = Date.parse(event.end)
                                let startdate = new Date(startTimestamp)
                                let enddate = new Date(endTimestamp)
                                startDate = startdate
                                endDate = enddate
                                startDate.setHours(0, 0, 1)
                                endDate.setHours(24, 0, 0)
                            }
                            colors.push({
                                start: event.allDay ? startDate : event.start,
                                end: event.allDay ? endDate : event.end,
                                resource: [1001],
                                // background: LightenDarkenColor(eventColor,50),
                                background: `#808080` // 0.3 opacity and event.color HEX
                            })
                        }
                    }

                    if (event.resource.some(r => equipmentResources.indexOf(r) >= 0)) {  // IF EVENT IN EQUIPMENT GROUP
                        //USED FOT ALTERNATIVE BACKGROUND HEX RENDERING
                        // let eventColor = event.color.substring(1)
                        let startDate
                        let endDate
                        if (event.allDay === true) {
                            let startTimestamp = Date.parse(event.start)
                            let endTimestamp = Date.parse(event.end)
                            let startdate = new Date(startTimestamp)
                            let enddate = new Date(endTimestamp)
                            startDate = startdate
                            endDate = enddate
                            startDate.setHours(0, 0, 1)
                            endDate.setHours(24, 0, 0)
                        }
                        colors.push({
                            start: event.allDay ? startDate : event.start,
                            end: event.allDay ? endDate : event.end,
                            resource: [101],
                            // background: LightenDarkenColor(eventColor,50),
                            background: `${event.color}${Math.floor(0.3 * 255).toString(16).padStart(2, 0)}` // 0.3 opacity and event.color HEX
                        })
                        if (!event.editable) {  // IF EVENT IN FREELANCE GROUP
                            //USED FOT ALTERNATIVE BACKGROUND HEX RENDERING
                            // let eventColor = event.color.substring(1)
                            let startDate
                            let endDate
                            if (event.allDay === true) {
                                let startTimestamp = Date.parse(event.start)
                                let endTimestamp = Date.parse(event.end)
                                let startdate = new Date(startTimestamp)
                                let enddate = new Date(endTimestamp)
                                startDate = startdate
                                endDate = enddate
                                startDate.setHours(0, 0, 1)
                                endDate.setHours(24, 0, 0)
                            }
                            colors.push({
                                start: event.allDay ? startDate : event.start,
                                end: event.allDay ? endDate : event.end,
                                resource: [101],
                                // background: LightenDarkenColor(eventColor,50),
                                background: `#808080` // 0.3 opacity and event.color HEX
                            })
                        }
                    }
                    // console.log(event.editable)
                })
                colors.push({ ...calendarColors })
                setCalendarColors(colors)
                // console.log(colors)
            }
        }
        freelanceResourceAvailable()

    }, [myEvents])




    const handleResourceCheckInEvent = (resource) => {
        if (!isChecked.includes(resource.id)) {
            setIsChecked([...isChecked, resource.id]);
        } else {
            const index = isChecked.indexOf(resource.id)
            const checked = isChecked
            if (index > -1) { // only splice array when item is found
                checked.splice(index, 1); // 2nd parameter means remove one item only
            }
            setIsChecked([...checked])
        }
    }

    const days = (date_1, date_2) => {
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    }

    const myScheduleEvent = React.useCallback((data) => {
        // console.log(data)
        const ev = data.original;
        const color = data.color;
        let difference = data.endDate.getTime() - data.startDate.getTime() + 1000
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        // console.log(TotalDays)
        if (ev.title === "Juleferie") {
            const lights = Math.round(TotalDays * 1.2);

            return (
                <div className={`christmas md-timeline-template-event md-timeline-template-event-christemas ${mobileShow ? "" : "mobile-toggle"}`}>
                    <div className="mbsc-schedule-event-inner md-timeline-template-event-cont-expand">
                        <div id="flex-event-expand">
                            <span className="md-timeline-template-title-christemas">PRIVAT: {ev.title} - {TotalDays} days</span>
                            <span className="md-timeline-template-desc-christemas" >{ev.description}</span>
                        </div>
                    </div>
                    <ul class="strand">
                        {Array.from(Array(lights), (e, i) => {
                            return <li key={i}></li>
                        })}
                    </ul>
                </div>
            )

        }
        else if (data.original.busy === true) {
            return <div className={`md-timeline-template-event md-timeline-template-event-busy ${mobileShow ? "" : "mobile-toggle"}`}>
                <div className="mbsc-schedule-event-inner md-timeline-template-event-cont-expand">
                    <div id="flex-event-expand">
                        <span className="md-timeline-template-title-busy">PRIVAT: {ev.title} - {TotalDays} days</span>
                        <span className="md-timeline-template-desc-busy" >{ev.description}</span>
                    </div>
                    <span className="md-timeline-template-time-busy">{
                        data.startDate.toLocaleString('default', { month: 'short', day: 'numeric' }) +
                        " " +
                        data.start +
                        " - " +
                        data.endDate.toLocaleString('default', { month: 'short', day: 'numeric' }) +
                        " " +
                        data.end
                    }
                    </span>
                </div>
            </div>

        }

        // else if (data.resource.length === 1 && data.resource.includes(4)) {

        //     return <div className={`md-timeline-template-event ${mobileShow ? "" : "mobile-toggle"}`} style={{
        //         height: "25px",
        //         borderColor: `${color}${Math.floor(1 * 255).toString(16).padStart(2, 0)}`,
        //         background: `${color}${Math.floor(0.4 * 255).toString(16).padStart(2, 0)}`
        //     }}>
        //         <div className="mbsc-schedule-event-inner md-timeline-template-event-cont-expand">
        //             <span className="md-timeline-template-title">{ev.title}  - "{ev.description}</span>
        //         </div>
        //     </div>
        // }
        else {

            return <div className={`md-timeline-template-event ${mobileShow ? "" : "mobile-toggle"}`} style={{
                borderColor: `${color}${Math.floor(1 * 255).toString(16).padStart(2, 0)}`,
                background: `${color}${Math.floor(0.4 * 255).toString(16).padStart(2, 0)}`
            }}>
                <div className="mbsc-schedule-event-inner md-timeline-template-event-cont-expand">
                    <div id="flex-event-expand">
                        <span className="md-timeline-template-title">{ev.title} - {TotalDays} days</span>
                        <span className="md-timeline-template-desc" >{ev.description}</span>
                    </div>
                    <span className="md-timeline-template-time">{
                        data.startDate.toLocaleString('default', { month: 'short', day: 'numeric' }) +
                        " " +
                        data.start +
                        " - " +
                        data.endDate.toLocaleString('default', { month: 'short', day: 'numeric' }) +
                        " " +
                        data.end
                    }
                    </span>
                </div>
            </div>
        }
    });

    const myScheduleEventContent = React.useCallback((data) => {
        const ev = data.original;
        console.log(ev)
        return <div className="md-timeline-template-event" >
            <span className="md-timeline-template-title">{ev.title}</span>
            <span className="md-timeline-template-time" >{ev.description}</span>
        </div>
    });


    const renderCustomResource = (resource) => {
        if (!resource.styling) {
            return (
                <div className="resource-template-content">
                    <h5 className="resource-name">{resource.name}</h5>
                    <div className="resource-description">{resource.description}</div>
                </div>
            )
        } else if (resource.styling === 'person') {
            return (
                <div className="resource-template-content">
                    <div className="resource-name">{resource.name}</div>
                    <div className="resource-description">{resource.description}</div>
                </div>
            )
        } else if (resource.styling === 'header') {
            return (
                <div className="resource-header-content">
                    <h6 className="resource-header-name">{resource.name}</h6>
                </div>
            )
        }
    }

    const renderResourceHeader = () => {
        return (
            <div className="md-resource-header-template-title">
                <div className="md-resource-header-template-name">Room</div>
                <div className="md-resource-header-template-seats">Capacity</div>
            </div>
        )
    }


    const invalidRecources = React.useMemo(() => {
        return [{
            recurring: {
                repeat: 'daily'
            },
            resource: ['100', '101', '1001'] // disable the parent resources with id 'gro2' and 'gro3'
        }]
    }, []);


    const calendarMarkedDates = React.useMemo(() => {
        return [
            new Date(2020, 2, 15),
            new Date(2020, 2, 22),
            {
                start: new Date(2020, 2, 23),
                end: new Date(2020, 2, 24),
                color: 'red'
            },
            {
                color: 'green',
                recurring: { repeat: 'yearly', month: 12, day: 24 }
            }
        ]
    }, []);



    function flattenArr(arr, flat = []) {
        for (let i = 0; i < arr.length; i++) {
            flat.push(arr[i]);
            if (arr[i].children && arr[i].children.length) {
                flat.pop() // remove headers from list
                flattenArr(arr[i].children, flat);
            }
        }
        return flat;
    }



    function flattenArrAll(arr, flat = []) {
        for (let i = 0; i < arr.length; i++) {
            flat.push(arr[i]);
            if (arr[i].children && arr[i].children.length) {
                flattenArrAll(arr[i].children, flat);
            }
        }
        return flat;
    }

    const handleEventBusy = React.useCallback((ev => {
        setIsBusy(ev.target.checked)
    }));

    const handleEventX = React.useCallback((ev => {
        setIsX(ev.target.checked)
    }));
    const handleEventInvoiced = React.useCallback((ev => {
        setIsEditable(ev.target.checked)
    }));



    const showMobileMenu = (e) => {
        setMobileShow(!mobileShow)
        let el1 = document.querySelectorAll(".motion-air-calendar .mbsc-timeline-resource-empty");
        let el2 = document.querySelectorAll(".motion-air-calendar .mbsc-timeline-resource-col");
        let el3 = document.querySelectorAll(".motion-air-calendar .mbsc-timeline-resources");
        let el4 = document.querySelectorAll(".mbsc-timeline-resource.mbsc-ios.mbsc-ltr");


        if (mobileShow === true) {

            el1.forEach(el => el.classList.add('mobile-menu-open'))
            el2.forEach(el => el.classList.add('mobile-menu-open'))
            el3.forEach(el => el.classList.add('mobile-menu-open'))
            el4.forEach(el => el.classList.add('mobile-menu-open-padding'))


        } else {
            el1.forEach(el => el.classList.remove('mobile-menu-open'))
            el2.forEach(el => el.classList.remove('mobile-menu-open'))
            el3.forEach(el => el.classList.remove('mobile-menu-open'))
            el4.forEach(el => el.classList.remove('mobile-menu-open-padding'))
        }
    }




    // STANDARD CODE:
    const saveEvent = React.useCallback(() => {
        const newEvent = {
            id: uuidv4(),
            title: popupEventTitle,
            description: popupEventDescription,
            start: popupEventDate[0].toString(),
            end: popupEventDate[1].toString(),
            allDay: popupEventAllDay,
            status: popupEventStatus,
            color: selectedColor,
            resource: isChecked,
            editable: isEditable,
            busy: isBusy
        };
        if (isEdit) {
            // update the event in the list
            const index = myEvents.findIndex(x => x.id === tempEvent.id);
            const newEventList = [...myEvents];

            newEventList.splice(index, 1, newEvent);

            // here you can update the event in your storage as well
            updateEvent(
                eventDatabase,
                tempEvent.id,
                popupEventTitle,
                popupEventDescription,
                popupEventDate[0],
                popupEventDate[1],
                popupEventAllDay,
                popupEventStatus,
                isBusy ? busyColor : selectedColor,
                isChecked,
                !isEditable,
                isBusy

            ).then(() => {
                toast({
                    message: 'Successfully edited event'
                });

                console.log('Successfully edited event with ID: ', tempEvent.id)
                fetchEvents(eventDatabase).then((events) => {
                    setMyEvents(events)
                })
            });

        } else {

            // add the new event to the list
            newEvent.color = busyColor;
            setMyEvents([...myEvents, newEvent]);

            // FROM Firebase
            addEvents(
                eventDatabase,
                uuidv4(),
                popupEventTitle,
                popupEventDescription,
                popupEventDate[0],
                popupEventDate[1],
                popupEventAllDay,
                popupEventStatus,
                isBusy ? busyColor : selectedColor,
                isChecked,
                !isEditable,
                isBusy
            )

        }
        setSelectedDate(popupEventDate[0]);
        fetchEvents(eventDatabase).then((events) => {
            setMyEvents(events)
        })

        // close the popup
        setOpen(false);
    }, [eventDatabase, isEditable, isEdit, myEvents, popupEventAllDay, popupEventDate, popupEventDescription, popupEventStatus, popupEventTitle, tempEvent, selectedColor, isChecked, isBusy]);

    const deleteLocalEvent = React.useCallback((event) => {

        deleteEvent(eventDatabase, event).then(() => {
            fetchEvents(eventDatabase).then((events) => {
                setMyEvents(events)
                console.log('event deleted')
            })
        })
        setTimeout(() => {
            snackbar({
                button: {
                    action: () => {
                        restoreEvent(eventDatabase, event).then(() => {
                            toast({
                                message: 'Event successfully restored'
                            });
                            fetchEvents(eventDatabase).then((events) => {
                                setMyEvents(events)
                            })
                        })
                    },
                    text: 'Undo'
                },
                message: 'Event deleted'

            });
            fetchEvents(eventDatabase).then((events) => {
                setMyEvents(events)
            })

        });
        toast({
            message: 'Event successfully deleted!'
        });
    }, []);

    const loadPopupForm = React.useCallback((event) => {
        setTitle(event.title);
        setDescription(event.description ? event.description : " ");
        setDate([event.start, event.end]);
        setAllDay(event.allDay || false);
        setStatus(event.status || 'busy');
    }, []);

    // handle popup form changes

    const titleChange = React.useCallback((ev) => {
        setTitle(ev.target.value);
    }, []);

    const descriptionChange = React.useCallback((ev) => {
        setDescription(ev.target.value);
    }, []);

    const allDayChange = React.useCallback((ev) => {
        setAllDay(ev.target.checked);
    }, []);

    const dateChange = React.useCallback((args) => {
        setDate(args.value);
    }, []);

    const statusChange = React.useCallback((ev) => {
        setStatus(ev.target.value);
    }, []);

    const onDeleteClick = React.useCallback(() => {
        deleteLocalEvent(tempEvent);
        setOpen(false);
    }, [deleteLocalEvent, tempEvent]);



    // scheduler options

    const onSelectedDateChange = React.useCallback((event) => {
        setSelectedDate(event.date);
    }, []);

    const onEventClick = React.useCallback((args) => {
        setEdit(true);
        setTempEvent({ ...args.event });
        setIsEditable(!args.event.editable)
        setSelectedColor(args.event.color || flattenArr(resources).find(r => r.id === args.event.resource).color);
        setIsBusy(args.event.busy)
        // fill popup form with event data
        loadPopupForm(args.event);
        setAnchor(args.domEvent.target);
        setOpen(true);

        // Custom code:
        // Deals with witch resources are selected for this event:
        setIsChecked(args.event.resource)
    }, [loadPopupForm]);

    const onEventCreated = React.useCallback((args) => {
        setIsEditable(false)
        setEdit(false);
        setTempEvent(args.event)
        setSelectedColor(colors[Math.floor(Math.random() * colors.length)]);
        // fill popup form with event data
        loadPopupForm(args.event);
        setAnchor(args.target);

        //Custom code:
        setIsChecked([args.event.resource])

        // open the popup
        setOpen(true);
    }, [loadPopupForm]);

    const onEventDeleted = React.useCallback((args) => {
        deleteLocalEvent(args.event)
    }, [deleteLocalEvent]);

    const onEventUpdated = React.useCallback((args) => {
        // here you can update the event in your storage as well, after drag & drop or resize

        updateEvent(
            eventDatabase,
            args.event.id,
            args.event.title,
            args.event.description,
            args.event.start,
            args.event.end,
            args.event.allDay,
            args.event.status,
            args.event.color,
            args.event.resource,
            args.event.editable,
            args.event.busy

        ).then(() => {
            toast({
                message: 'Successfully edited event'
            });

            fetchEvents(eventDatabase).then((events) => {
                setMyEvents(events)
                console.log('Successfully edited event with ID: ', args.event.id)
            })
        })
    }, []);

    // datepicker options
    const controls = React.useMemo(() => popupEventAllDay ? ['date'] : ['datetime'], [popupEventAllDay]);
    const responsiveOptions = React.useMemo(() => popupEventAllDay ? {
        medium: {
            controls: ['calendar'],
            touchUi: false
        }
    } : {
        medium: {
            controls: ['calendar', 'time'],
            touchUi: false
        }
    }, [popupEventAllDay]);

    // popup options
    const headerText = React.useMemo(() => isEdit ? 'Edit event' : 'New Event', [isEdit]);
    const popupButtons = React.useMemo(() => {
        if (isEdit) {
            return ['cancel', {
                handler: () => {
                    saveEvent();
                },
                keyCode: 'enter',
                text: 'Save',
                cssClass: 'mbsc-popup-button-primary'
            }];
        } else {
            return ['cancel', {
                handler: () => {
                    saveEvent();
                },
                keyCode: 'enter',
                text: 'Add',
                cssClass: 'mbsc-popup-button-primary'
            }];
        }
    }, [isEdit, saveEvent]);

    const onClose = React.useCallback(() => {
        if (!isEdit) {
            // refresh the list, if add popup was canceled, to remove the temporary event
            setMyEvents([...myEvents]);
        }
        setOpen(false);
    }, [isEdit, myEvents]);

    const selectColor = React.useCallback((color) => {
        setTempColor(color)
    }, []);

    const openColorPicker = React.useCallback((ev) => {
        selectColor(selectedColor);
        setColorAnchor(ev.currentTarget);
        setColorPickerOpen(true);
    }, [selectColor, selectedColor]);

    const changeColor = React.useCallback((ev) => {
        const color = ev.currentTarget.getAttribute('data-value');
        selectColor(color);
        if (!colorPicker.current.s.buttons.length) {
            setSelectedColor(color);
            setColorPickerOpen(false);
        }
    }, [selectColor, setSelectedColor]);

    const onPickerClose = React.useCallback(() => {
        setColorPickerOpen(false);
    }, []);



    const onCellClick = React.useCallback((ev) => {
        // console.log('Cell klicked', ev)
    }, []);



    const onEventHoverIn = React.useCallback((args) => {
        const event = args.event
        let startTime = event.start.toLocaleTimeString()
        let endTime = event.end.toLocaleTimeString()
        setTitle(event.title)
        setAllDay(event.allDay)
        setDescription(event.description)
        setAnchor(args.domEvent.target);
        setSelectedColor(event.color)
        setIsHoverOpen(true)
        setTempEvent(event)
        setTime([startTime.substring(0, startTime.length - 3), endTime.substring(0, endTime.length - 3)])
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        const resourcesInEvent = []
        args.event.resource.forEach(element => flattenArr(resources).filter((obj) => {
            if (obj.id == element) {
                resourcesInEvent.push(obj)
            }

        }))
        resourcesInEvent.sort(function (a, b) {
            return (a.id - b.id) || a.name.localeCompare(b.name);
        });
        setEventContent([...resourcesInEvent])

    }, []);

    const onEventHoverOut = React.useCallback(() => {
        timerRef.current = setTimeout(() => { setIsHoverOpen(false); }, 150);
    }, []);

    const onMouseEnter = React.useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    }, []);

    const onMouseLeave = React.useCallback(() => {
        timerRef.current = setTimeout(() => { setIsHoverOpen(false); }, 150);
    }, []);



    const cursorPosCalcX = (presence) => {
        let position = presence.cursor.x - scrollPos.x

        // IF ELSE ADJUSTS POSITION IF MOBILE MENU IS HIDDEN
        if (mobileShow === false) {
            position = presence.cursor.x - scrollPos.x - 190;
        } else {
            position = presence.cursor.x - scrollPos.x;
        }
        return position
    }

    const cursorPosCalcY = (presence) => {
        const position = presence.cursor.y - scrollPos.y
        return position
    }

    const updatePresence = () => {
        setTimeout(() => {
            updateMyPresence({ cursor: null })
        }, 900000)
    }



    const changeZoomLevel = (text, inst) => {
        const zLevels = [360, 480, 720, 1440]
        let currentZoom = zoomLevel;
        if (text === "minus") {
            if (currentZoom === 1440) {
                console.log('Zoom at maximum')
            } else {
                currentZoom = zLevels.findIndex((element, index) => {
                    if (element === currentZoom) {
                        setZoomLevel(zLevels[index + 1])

                        setTimeout(() => {
                            setSelectedDate(new Date())
                        }, 10)
                    }
                })
            }
        } else {
            if (currentZoom === 360) {
                console.log('Zoom at minimum')
            } else {
                currentZoom = zLevels.findIndex((element, index) => {
                    if (element === currentZoom) {
                        setZoomLevel(zLevels[index - 1]);

                        setTimeout(() => {
                            setSelectedDate(new Date());
                        }, 10)
                    }
                })
            }
        }
    }


    const scrollToUser = (clickedUser) => {
        if (clickedUser === user.email) {
            return;
        }
        const container = document.querySelector('.mbsc-timeline-grid-scroll');
        container.scrollLeft = findNestedObj(others, 'name', clickedUser).cursor.x - 500;
    }






    useEffect(() => {
        setNorwegianHolidays(holidays(formatDate('YYYY', mySelectedDate)));
    }, [mySelectedDate])



    // NOE FEIL MED SET HOLIDAYS HER. TROR DEN BLIR "CALLED" FOR MANGE GANGER INNIMELLOM...
    const onPageLoaded = (day) => {
        // setTimeout(() => {
        //     setCurrentCalendarYear(day.firstDay)
        //     console.log('Setting calendar year to state: ', currentCalendarYear)
        // })
    }


    const onHolidayMouseEnter = React.useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    }, []);

    const onHolidayMouseLeave = React.useCallback(() => {
        setIsHoverDayOpen(false)
        timerRef.current = setTimeout(() => { setIsHoverDayOpen(false); }, 10);
    }, []);


    const myDayContent = (day) => {

        const showHolidayInfo = (e, day) => {
            setHolidayAnchor(e.target)
            // FIKSER EN TULLETE BUG
            if (day.info.name === "Kristi Himmelsprettsdag") {
                setHolidayName("Kristi Himmelfartsdag")
            } else {
                setHolidayName(day.info.name)
            }
            setIsHoverDayOpen(true);

            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        }

        const date = day.date;
        let formattedDay = formatDate('DD.MM.YY', date)

        const holidayDates = []
        norwegianHolidays.forEach((holi) => {
            holidayDates.push({
                date: formatDate('DD.MM.YY', new Date(holi.date)),
                info: holi
            })
        })


        // HOLIDAYS
        if (holidayDates.map((date) => date.date).includes(formattedDay)) {
            var index = holidayDates.findIndex(v => v.date.includes(formattedDay));
            return <div
                className={`header-date-container calendar-holiday ${formatDate('YYYY-MM-DD', date)}`}
                onMouseOver={(e) => { showHolidayInfo(e, holidayDates[index]) }}
                onMouseLeave={onHolidayMouseLeave}
            >
                <div className='header-date-day'>{formatDate('DDD', date)}</div>
                <p className='header-date-date'>{formatDate('DD.MM.YY', date)}</p>
            </div>;

        }
        // SATURDAY
        else if (day.date.getDay() === 6) {
            return <div className={`header-date-container calendar-saturday ${formatDate('YYYY-MM-DD', date)}`}>
                <div className='header-date-day'>{formatDate('DDD', date)}</div>
                <p className='header-date-date'>{formatDate('DD.MM.YY', date)}</p>
            </div>;

        }
        // SUNDAY
        else if (day.date.getDay() === 0) {
            return <div className={`header-date-container calendar-sunday ${formatDate('YYYY-MM-DD', date)}`}>
                <div className='header-date-day'>{formatDate('DDD', date)}</div>
                <p className='header-date-date'>{formatDate('DD.MM.YY', date)}</p>
            </div>;
        }
        // TODAY
        else if (formatDate('YYYY-MM-DD', day.date) === formatDate('YYYY-MM-DD', new Date())) {
            return <div style={{ backgroundColor: "rgb(227, 255, 227)" }} className={`header-date-container calendar-sunday ${formatDate('YYYY-MM-DD', date)}`}>
                <div className='header-date-day'>{formatDate('DDD', date)}</div>
                <p className='header-date-date'>{formatDate('DD.MM.YY', date)}</p>
            </div>;
        }


        return <div className={`header-date-container ${formatDate('YYYY-MM-DD', date)}`}>
            <div className='header-date-day'>{formatDate('DDD', date)}</div>
            <p className='header-date-date'>{formatDate('DD.MM.YY', date)}</p>
        </div>;
    }

    const onTestClick = (e) => {
    }


    const myHeader = () => {
        return (
            <React.Fragment>
                <CalendarNav />
                <CalendarPrev />
                <CalendarToday />
                <CalendarNext />
                <Button id={"btnHideResource"} onClick={showMobileMenu}>
                    {mobileShow ? "Hide resource" : "Show resource"}
                </Button>
                {/* 
                BUTTONS FOR ZOOM FUNCTIONALITY. NOT WORKING
                <Button onClick={() => changeZoomLevel("plus")}> + </Button>
                <Button onClick={() => changeZoomLevel("minus")}> - </Button> */}
                <div className="cursor-users-online flex pl-3">
                    {others.slice(0, 3).map(({ connectionId, presence }) => {
                        if (presence.cursor === null) {
                            return null
                        }
                        return (
                            <Avatar
                                key={connectionId}
                                color={presence.color}
                                name={presence.name}
                                displayName={presence.displayName}
                                lastActive={presence.lastActive}
                                scrollToUser={scrollToUser}
                                profilePicture={presence.iconImage}
                            />
                        );
                    })}
                    {currentUser.presence.cursor ? (
                        <Avatar
                            color={currentUser.presence.color}
                            name={currentUser.presence.name}
                            displayName={'You'}
                            scrollToUser={scrollToUser}
                            profilePicture={userSettings.showProfilePictureInAvatar ? userSettings.userImage : ""}
                        />
                    )
                        : null
                    }
                </div>
            </React.Fragment>
        );
    }


    return <div
        className='motion-air-calendar-wrapper'
        onPointerMove={mouseMove}
        onPointerLeave={updatePresence}
    >{
            user ? <div>

                {others.map(({ connectionId, presence }) =>
                    presence.cursor ? (
                        <Cursor
                            key={connectionId}
                            x={cursorPosCalcX(presence)}
                            y={cursorPosCalcY(presence)}
                            name={presence.name}
                            color={presence.color}
                            id={connectionId}
                            profilePicture={presence.cursorImage ? presence.iconImage : ""}
                        />
                    ) : null
                )}

                <Eventcalendar
                    onCellClick={onTestClick}
                    className='motion-air-calendar'
                    view={viewSettings}
                    data={myEvents}
                    resources={resources}
                    clickToCreate="double"
                    dragToCreate={true}
                    dragToMove={true}
                    dragToResize={true}
                    selectedDate={mySelectedDate}
                    onSelectedDateChange={onSelectedDateChange}

                    onPageLoaded={onPageLoaded}
                    // selectedDate={selectedDate}

                    onEventClick={onEventClick}
                    onEventCreated={onEventCreated}
                    onEventDeleted={onEventDeleted}
                    onEventUpdated={onEventUpdated}
                    colors={calendarColors}
                    renderScheduleEvent={myScheduleEvent}
                    renderScheduleEventContent={myScheduleEventContent}
                    renderHeader={myHeader}
                    invalid={invalidRecources}
                    onEventHoverIn={onEventHoverIn}
                    onEventHoverOut={onEventHoverOut}
                    ref={timelineRef}
                    renderDay={myDayContent}
                // renderResource={renderCustomResource}
                // renderResourceHeader={renderResourceHeader}
                >
                </Eventcalendar>
                <Popup
                    display="bottom"
                    fullScreen={true}
                    contentPadding={false}
                    headerText={headerText}
                    anchor={anchor}
                    buttons={popupButtons}
                    isOpen={isOpen}
                    onClose={onClose}
                    responsive={responsivePopup}
                    closeOnOverlayClick={true}
                >
                    <div className="mbsc-form-group">
                        {isEditable ? <Input disabled={true} label="Title" value={popupEventTitle} onChange={titleChange} ></Input> : <Input label="Title" value={popupEventTitle} onChange={titleChange} ></Input>}
                        {isEditable ? <Textarea disabled={true} label="Description" value={popupEventDescription} onChange={descriptionChange} /> : <Textarea label="Description" value={popupEventDescription} onChange={descriptionChange} />}
                        <div onClick={openColorPicker} className="event-color-c">
                            <div className="event-color-label">Color</div>
                            <div className="event-color" style={{ background: selectedColor }}></div>
                        </div>
                        <div
                            className={`invoiced ${isEditable ? "completed" : ""}`}
                            style={{ backgroundColor: isEditable ? 'rgb(141, 244, 172)' : 'white' }}
                        >
                            <Checkbox disabled={isEditable ? true : false} label={'Ikke ledig'} defaultChecked={isBusy} onChange={handleEventBusy} />
                            <Checkbox disabled={true} label={'En her jeg ikke kommer på'} defaultChecked={isX} onChange={handleEventX} />
                            <Checkbox disabled={isBusy ? true : false} label={'Invoiced'} defaultChecked={isEditable} onChange={handleEventInvoiced} />
                        </div>
                        {isEditable ? <Switch disabled={true} label="All-day" checked={popupEventAllDay} onChange={allDayChange} /> : <Switch label="All-day" checked={popupEventAllDay} onChange={allDayChange} />}
                        {isEditable ? <Input disabled={true} ref={startRef} label="Starts" /> : <Input ref={startRef} label="Starts" />}
                        {isEditable ? <Input disabled={true} ref={endRef} label="Ends" /> : <Input ref={endRef} label="Ends" />}
                        <Datepicker
                            select="range"
                            controls={controls}
                            touchUi={true}
                            startInput={start}
                            endInput={end}
                            showRangeLabels={false}
                            responsive={responsiveOptions}
                            onChange={dateChange}
                            value={popupEventDate}
                        />
                        <div className='resource-row-container'>
                            <div className='resource-row-1'>
                                {flattenArr(resources).map((item, index) => {
                                    // LastCrew sets the position of the whitespace on the popup component
                                    var lastCrew = resources[0].children.length - 1 + 3;
                                    if (index === lastCrew) {
                                        if (isEditable || isBusy === true) {
                                            return (
                                                <div className='last-crew-entry' key={item.id}>
                                                    <Checkbox disabled={true} label={item.name} defaultChecked={isChecked.includes(item.id)} onChange={() => handleResourceCheckInEvent(item)} />
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className='last-crew-entry' key={item.id}>
                                                    <Checkbox label={item.name} defaultChecked={isChecked.includes(item.id)} onChange={() => handleResourceCheckInEvent(item)} />
                                                </div>
                                            )
                                        }
                                    }
                                    if (flattenArr(resources).length / 2 > index) {
                                        if (isEditable || isBusy) {
                                            return (
                                                <div key={item.id}>
                                                    <Checkbox disabled={true} label={item.name} defaultChecked={isChecked.includes(item.id)} onChange={() => handleResourceCheckInEvent(item)} />
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div key={item.id}>
                                                    <Checkbox label={item.name} defaultChecked={isChecked.includes(item.id)} onChange={() => handleResourceCheckInEvent(item)} />
                                                </div>
                                            )
                                        }
                                    }
                                })}
                            </div>
                            <div className='resource-row-2'>
                                {flattenArr(resources).map((item, index) => {
                                    if (flattenArr(resources).length / 2 <= index) {
                                        if (isEditable || isBusy) {
                                            return (
                                                <div key={item.id}>
                                                    <Checkbox disabled={true} label={item.name} defaultChecked={isChecked.includes(item.id)} onChange={() => handleResourceCheckInEvent(item)} />
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div key={item.id}>
                                                    <Checkbox label={item.name} defaultChecked={isChecked.includes(item.id)} onChange={() => handleResourceCheckInEvent(item)} />
                                                </div>
                                            )
                                        }
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="mbsc-form-group">
                        {/* {isEditable ? <Switch disabled={true} label="All-day" checked={popupEventAllDay} onChange={allDayChange} /> : <Switch label="All-day" checked={popupEventAllDay} onChange={allDayChange} />}
                        {isEditable ? <Input disabled={true} ref={startRef} label="Starts" /> : <Input ref={startRef} label="Starts" />}
                        {isEditable ? <Input disabled={true} ref={endRef} label="Ends" /> : <Input ref={endRef} label="Ends" />}
                        <Datepicker
                            select="range"
                            controls={controls}
                            touchUi={true}
                            startInput={start}
                            endInput={end}
                            showRangeLabels={false}
                            responsive={responsiveOptions}
                            onChange={dateChange}
                            value={popupEventDate}
                        /> */}
                        {isEdit && <div className="mbsc-button-group">
                            <Button className="mbsc-button-block" color="danger" variant="outline" onClick={onDeleteClick}>Delete event</Button>
                        </div>}
                    </div>
                </Popup>
                <Popup
                    display="bottom"
                    contentPadding={false}
                    showArrow={false}
                    showOverlay={false}
                    anchor={colorAnchor}
                    isOpen={colorPickerOpen}
                    buttons={colorButtons}
                    responsive={colorPopup}
                    ref={colorPicker}
                    onClose={onPickerClose}
                >
                    <div className="crud-color-row">
                        {colors.map((color, index) => {
                            if (index < 5) {
                                return <div key={index} onClick={changeColor} className={"crud-color-c " + (tempColor === color ? 'selected' : '')} data-value={color}>
                                    <div className="crud-color mbsc-icon mbsc-font-icon mbsc-icon-material-check" style={{ background: color }}></div>
                                </div>
                            } else return null;
                        })}
                    </div>
                    <div className="crud-color-row">
                        {colors.map((color, index) => {
                            if (index >= 5) {
                                return <div key={index} onClick={changeColor} className={"crud-color-c " + (tempColor === color ? 'selected' : '')} data-value={color}>
                                    <div className="crud-color mbsc-icon mbsc-font-icon mbsc-icon-material-check" style={{ background: color }}></div>
                                </div>
                            } else return null;
                        })}
                    </div>
                </Popup>

                {/* HOVER POPUP */}
                <Popup
                    display="anchored"
                    anchor={anchor}
                    touchUi={false}
                    showOverlay={false}
                    contentPadding={false}
                    closeOnOverlayClick={false}
                    width={300}
                    // cssClass="md-tooltip"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    isOpen={isHoverOpen} >
                    <div className="md-tooltip-container" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                        <div className="md-tooltip-header" style={{ backgroundColor: `${selectedColor}${Math.floor(0.4 * 255).toString(16).padStart(2, 0)}` }}>
                            <h1>{popupEventTitle}</h1>
                            <p className='popup-desc'>{popupEventDescription}</p>
                            {!popupEventAllDay ? <p className='popup-time'>{popupEventTime[0]} - {popupEventTime[1]}</p> : <></>}
                        </div>
                        <div className="md-tooltip-content-container">
                            <div className="md-tooltip-content">
                                <h2 className='selected-event-container'>Bemanning:</h2>
                                {eventContent.map(item => {
                                    if (item.id <= 11) {
                                        return <div className='selected-event-crew' key={item.id}>{item.name}</div>
                                    }
                                })}
                                <h2 className='selected-event-container'>{Math.max(...eventContent.map(item => item.id)) > 10 ? 'Equipment' : ''}</h2>
                                {eventContent.map(item => {
                                    if (item.id > 11) {
                                        return <div className='selected-event-equipment' key={item.id}>{item.name}</div>
                                    } else if (!item) {
                                        return <div></div>
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </Popup>

                {/* Popup for hovering holiday dates in header */}
                <Popup
                    display="anchored"
                    anchor={holidayAnchor}
                    touchUi={false}
                    showOverlay={false}
                    contentPadding={false}
                    closeOnOverlayClick={false}
                    width={"auto"}
                    onMouseEnter={onHolidayMouseEnter}
                    onMouseLeave={onHolidayMouseLeave}
                    isOpen={isHoverDayOpen} >
                    <div className="md-tooltip-container" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                        <div >
                            <p style={{ textAlign: "center" }} className='popup-desc'>{holidayName}</p>
                        </div>
                    </div>
                </Popup>

            </div> : <></>}
    </div>
}
export default Calendar;
