import React from "react";
import  "./Avatar.css";
import { useAuth } from '../Hooks/useAuth';

const IMAGE_SIZE = 48;


const convertHexToRGBA = (hexCode, opacity = 1) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};


export function Avatar({ name, displayName, color, lastActive, scrollToUser, profilePicture }) {
  // console.log(color)
  const { userSettings } = useAuth()


  var startTime = new Date(lastActive);
  function getMinutesBetweenDates(startDate, endDate) {
    var diff = endDate.getTime() - startDate.getTime();
    return (diff / 60000);
  }

  return (
    <div
      className={'avatar-container'}
      data-tooltip={name}
      onClick={() => {scrollToUser(name)}}
    >
      <p>{name.charAt(0).toUpperCase()}</p>
      {/* { userSettings.showProfilePictureInAvatar ?
      } */}
      <div
        style={{
          background: profilePicture ? `` : convertHexToRGBA(color.dim, 0.5),
          
          // backgroundImage: `url(${userSettings.userImage})`,

          backgroundImage: profilePicture ? `url(${profilePicture})` : "",
          backgroundSize: "contain",
          userSelect: 'none',
          boxShadow: `inset 0px 0px 0px 2px ${color.color}, 0px 8px 16px rgba(0,0,0,0.4)`,
          BorderColor: color.color
        }}
        alt="name"
        height={IMAGE_SIZE}
        width={IMAGE_SIZE}
        className={'avatar-background'}
      >
        <span className="avatar-text">{displayName}
          <br></br>
          <span
            style={{
              width: 'min-content',
              fontSize: '10px',
              zIndex: 9999
            }}
          >
            {
              getMinutesBetweenDates(startTime, new Date()) ?
                'last active: ' + Math.round(getMinutesBetweenDates(startTime, new Date()))+ " minutes ago"
                : ""
            }
          </span>
        </span>
      </div>
    </div>
  );
}