import { createClient } from "@liveblocks/client";
import { createRoomContext } from "@liveblocks/react";

const client = createClient({
  publicApiKey: process.env.REACT_APP_LIVEBLOCKS_API
});

export const {
  suspense: {
    RoomProvider,
    useOthers,
    useUpdateMyPresence,
    useOthersMapped,
    useSelf,
    useMyPresence
  },
} = createRoomContext(client);